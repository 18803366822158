<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('customerName')">
          <el-input v-model="queryForm.name" size="small" :placeholder="$t('customerName')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('linkPhone')">
          <el-input v-model="queryForm.phoneNo" size="small" :placeholder="$t('linkPhone')"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('accountType')">
          <el-select v-model="queryForm.type" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('individualAccount')" value="5"></el-option>
            <el-option :label="$t('companyAccount')" value="6"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- 区域选择器 -->
        <el-form-item :label="$t('region')">
          <el-select v-model="queryForm.region" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-for="(item,index) in countryList">
              <el-option-group v-if="item.parentId==0" :key="index" :label="item.name">
                <template v-for="(it,i) in countryList">
                  <el-option v-if="it.parentId==item.id" :key="i+index.toString()" :label="it.name" :value="it.id"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <!-- 城市选择器 -->
        <el-form-item :label="$t('city2')">
          <el-select v-model="queryForm.city" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-if="queryForm.region">
              <template v-for="(item,index) in countryList">
                <template v-if="item.parentId==0">
                  <template v-for="(it,i) in countryList">
                    <el-option-group v-if="it.id==queryForm.region&&it.parentId==item.id" :key="i+index.toString()" :label="item.name+'/'+it.name">
                      <template v-for="(em,i2) in countryList">
                        <el-option v-if="em.parentId==queryForm.region" :key="i2+i.toString()+index" :label="em.name" :value="em.id"></el-option>
                      </template>
                    </el-option-group>
                  </template>
                </template>
              </template>
            </template>
            <template v-else>
              <template v-for="(item,index) in countryList">
                <template v-if="item.parentId==0">
                  <template v-for="(it,i) in countryList">
                    <el-option-group v-if="it.parentId==item.id" :key="i+index.toString()" :label="item.name+'/'+it.name">
                      <template v-for="(em,i2) in countryList">
                        <el-option v-if="em.parentId==it.id" :key="i2+i.toString()+index" :label="em.name" :value="em.id"></el-option>
                      </template>
                    </el-option-group>
                  </template>
                </template>
              </template>
            </template>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('status')">
          <el-select v-model="queryForm.status" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('normal')" value="0"></el-option>
            <el-option :label="$t('lock')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isloading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>

      <el-row class="table-btns">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="openAccountDrawer=true">{{$t('Adding a Customer Account')}}</el-button>
      </el-row>
    </div>

    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column prop="name" fixed="left" :label="$t('customerName')" width="160">
          <template slot-scope="scope">{{scope.row.name+' '+ scope.row.surname}}</template>
        </el-table-column>
        <el-table-column prop="phoneNo" fixed="left" :label="$t('linkPhone')" min-width="160"></el-table-column>
        <el-table-column prop="customerCode" :label="$t('customerCode')" width="160"></el-table-column>
        <el-table-column prop="loginName" :label="$t('loginAccount')" width="160"></el-table-column>
        <!-- <el-table-column prop="type" :label="$t('accountType')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.type==5" class="text-primary">{{$t('individualAccount')}}</span>
            <span v-if="scope.row.type==6" class="text-primary">{{$t('companyAccount')}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="status" :label="$t('status')" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-success">{{$t('normal')}}</span>
            <span v-if="scope.row.status==1" class="text-danger">{{$t('lock')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="idCard" :label="$t('idNumber')" min-width="200"></el-table-column>
        <el-table-column prop="idImg" :label="$t('idCard')" min-width="200">
          <template slot-scope="scope">
            <el-image style="max-height:23px;" :src="scope.row.idImgs?scope.row.idImgs[0]:''" :preview-src-list="scope.row.idImgs">
              <div slot="error" class="image-slot">
                <img :src="imgError" style="max-height:23px;vertical-align: text-top;">
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="gender" :label="$t('sex')" min-width="160">
          <template slot-scope="scope">{{$t('sex'+scope.row.gender)}}</template>
        </el-table-column>
        <el-table-column prop="marryStatus" :label="$t('maritalStatus')" min-width="160">
          <template slot-scope="scope">{{scope.row.marryStatus?$t('maritalStatus'+scope.row.marryStatus):''}}</template>
        </el-table-column>
        <el-table-column prop="phoneNoBak" :label="$t('alternatePhone')" min-width="240">
          <template slot-scope="scope">
            <span>{{scope.row.phoneNoBak1||''}}</span>
            <span style="margin:0 10px"></span>
            <span>{{scope.row.phoneNoBak2||''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" :label="$t('area')" min-width="300">
          <template slot-scope="scope">
            {{getArea([scope.row.country,scope.row.region,scope.row.city])}}
          </template>
        </el-table-column>
        <el-table-column prop="address" :label="$t('address')" min-width="300"></el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" min-width="300" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="220">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" min-width="260">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==0" @click="onLock(scope.row,1)" size="mini" type="primary">{{$t('lock')}}</el-button>
            <el-button v-if="scope.row.status==1" @click="onLock(scope.row,0)" size="mini" type="warning">{{$t('unlock')}}</el-button>
            <el-button @click="showServerFeeConfig(scope.row)" size="mini" type="success" :loading="isloading">{{$t('businessConfig')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <!-- 锁定/解锁账户弹框 -->
    <el-drawer :title="$t(lockAccount.form.status==1?'lockAccount':'unlockAccount')" :visible.sync="lockAccount.drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="lockAccount.form" :rules="rules" ref="lockAccount">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('customerName')">
              <el-input v-model="lockAccount.row.userName" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('customerCode')">
              <el-input v-model="lockAccount.row.customerCode" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('loginAccount')">
              <el-input v-model="lockAccount.row.loginName" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('linkPhone')">
              <el-input v-model="lockAccount.row.phoneNo" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-button">
        <el-button size="medium" type="primary" :loading="isloading" @click="onSubmitLock('lockAccount')">{{$t(lockAccount.form.status==1?'lockAccount':'unlockAccount')}}</el-button>
      </div>
    </el-drawer>

    <!-- 新客户开户弹窗 -->
    <el-drawer :title="$t('Adding a Customer Account')" :visible.sync="openAccountDrawer" direction="rtl" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="1200">
      <!-- form组件 -->
      <iafp :business="{businessCode:14,type:7}"></iafp>
    </el-drawer>

    <!-- 平台客户单独配置手续费 -->
    <el-drawer :title="$t('businessConfig')" :visible.sync="businessConfigDrawer" direction="rtl" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="800">
      <el-form ref="configForm" :rules="rules">
        <el-row :gutter="20" v-for="item in configForm.buslist" :key="item.id">
          <el-col :span="6">
            <el-form-item :label="$t('businessName')">
              <el-input size="medium" :placeholder="$t(item.name)" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item :label="$t('serviceFeeRules')">
              <el-select size="medium" v-model="item.serviceChargeId" multiple>
                <template v-for="op in serviceChargeList">
                  <el-option v-if="item.businessCode==op.serviceChargeCode" :key="op.id" :label="`${op.name} (${op.minValue}~${op.maxValue}) (${op.settlementAmount}${(op.settlementRules==1?'%':'')})`" :value="op.id"></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button type="primary" class="bottom-button" :loading="isLoading" @click="onSubmitServiceChargeConfig('configForm')">{{$t('submit')}}</el-button>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>
import customer from '@/api/customer'
import business from '@/api/business'
import iafp from '@/components/openAccount/individualAccountFormPlatform.vue'
export default {
  components: { iafp },
  name: "platformForCustomer",
  data () {
    return {
      queryForm: {},
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,

      isloading: false,

      lockAccount: {
        drawer: false,
        form: {},
        row: {}
      },
      /**新增客户弹框 */
      openAccountDrawer: false,

      /**手续费配置弹框 */
      businessConfigDrawer: false,
      customerBusinessList: [],
      serviceChargeList: [],
      isLoading: false,
      configForm: {}
    }
  },
  computed: {
    countryList () { return this.$store.state.globalParameter.countryList; },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; },
    imgError () { return this.$store.state.system.imgError; },
    rules () { return {} },
  },
  watch: {
    /**选中省份后清空城市 */
    "queryForm.region" () { this.$set(this.queryForm, 'city', '') },
    // "queryForm.city" (value) {
    //   console.log("-----city------", value)
    //   if (!value || this.queryForm.region) return;
    //   let city = this.countryList.find(function (item) { return item.id == value });
    //   this.queryForm.region = city.parentId;
    // }
  },
  methods: {
    /**获取客户列表 */
    getList () {
      let _this = this;
      _this.isloading = true;
      customer.getPage({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm, cusType: 1 },
        success: res => {
          _this.isloading = false;
          if (res.code != 200) {
            _this.tableData = {};
            return;
          }
          for (let i = 0; i < res.data.records.length; i++) {
            let l = res.data.records[i];
            l.idImgs = l.idImg ? l.idImg.split(',') : l.idImg;
          }
          _this.tableData = res.data;
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    getArea (arr) {
      let text = '';
      for (let i = 0; i < arr.length; i++) {
        let g = this.countryList.find(function (t) { return t.id == arr[i] })
        text += (g ? g.name : '') + ' / ';
      }
      return text.substring(0, text.length - 3);
    },
    onLock (row, status) {
      row.userName = row.name + ' ' + row.surname;
      this.lockAccount.row = row;
      this.lockAccount.form = { id: row.id, status: status };
      this.lockAccount.drawer = true;
    },

    /**提交账户锁定信息 */
    onSubmitLock (refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        customer.updateCustomerById({
          param: this.lockAccount.form,
          success: res => {
            if (res.code == 200) {
              this.getList();
              this.lockAccount.drawer = false;
              this.$message.success(this.$t('operateSuccessfully'));
            }
            else
              this.$message.warning(this.$t(res.msg));
          }
        })
      })
    },

    /**显示手续费配置弹框 */
    showServerFeeConfig (row) {
      // this.$set(this, 'configForm', {});
      this.$set(this, 'isloading', true)
      this.$set(this.configForm, 'customerId', row.id);

      // 业务列表对象不为空时直接赋值，否则请求列表
      this.$set(this.configForm, 'buslist', JSON.parse(JSON.stringify(this.customerBusinessList)));

      this.getServerChargeConfig(row.id)
    },

    /**获取客户业务列表 */
    getCustomerBusinessList () {
      business.getList({
        success: res => {
          if (res.code != 200) return;
          let list = res.data.filter(t => t.businessCode < 80 && t.businessCode >= 70)
          this.$set(this, 'customerBusinessList', list)

          this.$set(this.configForm, 'buslist', list);
        }
      })
    },

    /**获取手续费列表 */
    getServiceChargeList () {
      let _this = this;
      business.getServiceChargeList({
        success: res => {
          if (res.code != 200) return;
          _this.serviceChargeList = res.data;
        }
      })
    },
    /**提交平台客户手续费配置 */
    onSubmitServiceChargeConfig (refname) {
      let s = [];
      let datas = this[refname].buslist
      for (let i = 0; i < datas.length; i++) {
        s.push(...datas[i].serviceChargeId);
      }

      let param = { customerId: this.configForm.customerId, serviceChargeIds: s.toString() }
      customer.bindingCusSer({
        param: param, success: res => {
          if (res.code == 200) {
            this.$notify.success({ message: this.$t('operateSuccessfully') })
            this.getList();
          } else {
            this.$notify.error(); ({ message: this.$t(res.msg || 'operationFailure') + '；' + res.code })
          }
        }
      })
    },

    /**查询客户手续费配置 */
    getServerChargeConfig (id) {
      customer.listCusSer({
        param: { customerId: id }, success: res => {
          this.$set(this, 'isloading', false)
          this.$set(this, 'businessConfigDrawer', true);
          if (res.code != 200) return;

          let buslist = JSON.parse(JSON.stringify(this.configForm?.buslist))
          for (let i in res.data) {
            let bus = buslist?.find(b => b.businessCode == res.data[i].serviceChargeCode);
            if (!bus) {
              continue;
            } else if (bus?.serviceChargeId) {
              bus.serviceChargeId.push(res.data[i].id)
            } else {
              bus.serviceChargeId = [res.data[i].id]
            }
          }
          this.$set(this.configForm, 'buslist', buslist)
        }
      })
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';

    this.getServiceChargeList();
    this.getCustomerBusinessList();
    this.getList();
  }
}
</script>

<style lang="less" scoped>
.table-page-head {
  /deep/ .el-form .el-form-item:last-child .el-form-item__content {
    min-width: unset;
    width: auto;
  }
}

.my-card {
  position: absolute;
  top: 0;
  bottom: 20px;
  left: 20px;
  right: 20px;
  .el-form-item .el-form-item__content .el-select {
    width: 100%;
  }
  /deep/ .el-card__body {
    height: calc(100% - 30px);
  }
}
</style>